export const routes = [
  {
    path: '/agents',
    /* internal name: '/agents' */
    /* no component */
    children: [
      {
        path: '',
        name: '/agents/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/index.vue'),
        /* no children */
        meta: {
          "namespace": "agents"
        }
      },
      {
        path: 'error',
        name: '/agents/error',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/error.vue'),
        /* no children */
        meta: {
          "auth": false,
          "namespace": "agents"
        }
      },
      {
        path: 'login',
        name: '/agents/login',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/login.vue'),
        /* no children */
        meta: {
          "auth": false,
          "namespace": "agents"
        }
      },
      {
        path: 'profile',
        /* internal name: '/agents/profile' */
        /* no component */
        children: [
          {
            path: 'balance_records',
            /* internal name: '/agents/profile/balance_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/agents/profile/balance_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/profile/balance_records/index.vue'),
                /* no children */
                meta: {
                  "namespace": "agents"
                }
              }
            ],
            meta: {
              "namespace": "agents"
            }
          },
          {
            path: 'password',
            /* internal name: '/agents/profile/password' */
            /* no component */
            children: [
              {
                path: '',
                name: '/agents/profile/password/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/profile/password/index.vue'),
                /* no children */
                meta: {
                  "namespace": "agents"
                }
              }
            ],
            meta: {
              "namespace": "agents"
            }
          },
          {
            path: 'point_records',
            /* internal name: '/agents/profile/point_records' */
            /* no component */
            children: [
              {
                path: '',
                name: '/agents/profile/point_records/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/profile/point_records/index.vue'),
                /* no children */
                meta: {
                  "namespace": "agents"
                }
              }
            ],
            meta: {
              "namespace": "agents"
            }
          },
          {
            path: 'purchases',
            /* internal name: '/agents/profile/purchases' */
            /* no component */
            children: [
              {
                path: ':id',
                /* internal name: '/agents/profile/purchases/[id]' */
                /* no component */
                children: [
                  {
                    path: 'success',
                    name: '/agents/profile/purchases/[id]/success',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/profile/purchases/[id]/success.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  }
                ],
                meta: {
                  "namespace": "agents"
                }
              },
              {
                path: 'new',
                name: '/agents/profile/purchases/new',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/profile/purchases/new.vue'),
                /* no children */
                meta: {
                  "namespace": "agents"
                }
              }
            ],
            meta: {
              "namespace": "agents"
            }
          }
        ],
        meta: {
          "namespace": "agents"
        }
      },
      {
        path: 'territories',
        /* internal name: '/agents/territories' */
        /* no component */
        children: [
          {
            path: ':territory_id',
            /* internal name: '/agents/territories/[territory_id]' */
            /* no component */
            children: [
              {
                path: 'invitations',
                /* internal name: '/agents/territories/[territory_id]/invitations' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/agents/territories/[territory_id]/invitations/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/invitations/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  }
                ],
                meta: {
                  "namespace": "agents"
                }
              },
              {
                path: 'joint_evaluations',
                /* internal name: '/agents/territories/[territory_id]/joint_evaluations' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/agents/territories/[territory_id]/joint_evaluations/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  },
                  {
                    path: ':evaluation_id',
                    /* internal name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/index.vue'),
                        /* no children */
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'batch_assign_homeworks',
                        /* internal name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: ':batch_assign_homework_id',
                            /* internal name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index.vue'),
                                /* no children */
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'edit',
                                name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit.vue'),
                                /* no children */
                                meta: {
                                  "namespace": "agents"
                                }
                              }
                            ],
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: 'new',
                            name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'homeworks',
                        /* internal name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/homeworks' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/homeworks/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/homeworks/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'joint_evaluation_courseware_relations',
                        /* internal name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: 'new',
                            name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "agents"
                    }
                  },
                  {
                    path: ':evaluation_id/edit',
                    name: '/agents/territories/[territory_id]/joint_evaluations/[evaluation_id].edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id].edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  },
                  {
                    path: 'new',
                    name: '/agents/territories/[territory_id]/joint_evaluations/new',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/new.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  }
                ],
                meta: {
                  "namespace": "agents"
                }
              },
              {
                path: 'orders',
                /* internal name: '/agents/territories/[territory_id]/orders' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/agents/territories/[territory_id]/orders/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  },
                  {
                    path: 'details',
                    name: '/agents/territories/[territory_id]/orders/details',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/details.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  },
                  {
                    path: 'summary',
                    name: '/agents/territories/[territory_id]/orders/summary',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/summary.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  }
                ],
                meta: {
                  "namespace": "agents"
                }
              },
              {
                path: 'school_income_stats',
                /* internal name: '/agents/territories/[territory_id]/school_income_stats' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/agents/territories/[territory_id]/school_income_stats/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/school_income_stats/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  }
                ],
                meta: {
                  "namespace": "agents"
                }
              },
              {
                path: 'schools',
                /* internal name: '/agents/territories/[territory_id]/schools' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/agents/territories/[territory_id]/schools/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "agents"
                    }
                  },
                  {
                    path: ':school_id',
                    /* internal name: '/agents/territories/[territory_id]/schools/[school_id]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/agents/territories/[territory_id]/schools/[school_id]/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/index.vue'),
                        /* no children */
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'clazzes',
                        /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: ':clazz_id',
                            /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/index.vue'),
                                /* no children */
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'agent_transaction_records',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records' */
                                /* no component */
                                children: [
                                  {
                                    path: ':agent_transaction_record_id',
                                    /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]' */
                                    /* no component */
                                    children: [
                                      {
                                        path: '',
                                        name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/',
                                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/index.vue'),
                                        /* no children */
                                        meta: {
                                          "namespace": "agents"
                                        }
                                      },
                                      {
                                        path: 'form',
                                        name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/form',
                                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/form.vue'),
                                        /* no children */
                                        meta: {
                                          "namespace": "agents"
                                        }
                                      }
                                    ],
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'homeworks',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks' */
                                /* no component */
                                children: [
                                  {
                                    path: ':homework_id',
                                    /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]' */
                                    /* no component */
                                    children: [
                                      {
                                        path: '',
                                        name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/',
                                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index.vue'),
                                        /* no children */
                                        meta: {
                                          "namespace": "agents"
                                        }
                                      },
                                      {
                                        path: 'homework_papers',
                                        /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers' */
                                        /* no component */
                                        children: [
                                          {
                                            path: ':homework_paper_id',
                                            /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]' */
                                            /* no component */
                                            children: [
                                              {
                                                path: '',
                                                name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/',
                                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index.vue'),
                                                /* no children */
                                                meta: {
                                                  "namespace": "agents"
                                                }
                                              }
                                            ],
                                            meta: {
                                              "namespace": "agents"
                                            }
                                          }
                                        ],
                                        meta: {
                                          "namespace": "agents"
                                        }
                                      }
                                    ],
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'students',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/students' */
                                /* no component */
                                children: [
                                  {
                                    path: 'new',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/students/new',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/students/new.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'studentships',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships' */
                                /* no component */
                                children: [
                                  {
                                    path: '',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/index.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  },
                                  {
                                    path: 'new',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/new',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/new.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'teachers',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teachers' */
                                /* no component */
                                children: [
                                  {
                                    path: 'new',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teachers/new',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teachers/new.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'teacherships',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships' */
                                /* no component */
                                children: [
                                  {
                                    path: '',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/index.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  },
                                  {
                                    path: ':teachership_id',
                                    /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]' */
                                    /* no component */
                                    children: [
                                      {
                                        path: 'edit',
                                        name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit',
                                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit.vue'),
                                        /* no children */
                                        meta: {
                                          "namespace": "agents"
                                        }
                                      }
                                    ],
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  },
                                  {
                                    path: 'new',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/new',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/new.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              },
                              {
                                path: 'workathons',
                                /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/workathons' */
                                /* no component */
                                children: [
                                  {
                                    path: '',
                                    name: '/agents/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/',
                                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/index.vue'),
                                    /* no children */
                                    meta: {
                                      "namespace": "agents"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "agents"
                                }
                              }
                            ],
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'events',
                        /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/events' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/events/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/events/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: ':id',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/events/[id]',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/events/[id].vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'school_homework_reports',
                        /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/school_homework_reports' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/school_homework_reports/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/school_homework_reports/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'textbook_forms',
                        /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/textbook_forms' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/textbook_forms/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: ':textbook_form_id/edit',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/textbook_forms/[textbook_form_id].edit',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/[textbook_form_id].edit.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          },
                          {
                            path: 'new',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/textbook_forms/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      },
                      {
                        path: 'workathons',
                        /* internal name: '/agents/territories/[territory_id]/schools/[school_id]/workathons' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/agents/territories/[territory_id]/schools/[school_id]/workathons/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/workathons/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "agents"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "agents"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "agents"
                    }
                  }
                ],
                meta: {
                  "namespace": "agents"
                }
              }
            ],
            meta: {
              "namespace": "agents"
            }
          }
        ],
        meta: {
          "namespace": "agents"
        }
      }
    ],
    meta: {
      "namespace": "agents"
    }
  },
  {
    path: '/schoolmen',
    /* internal name: '/schoolmen' */
    /* no component */
    children: [
      {
        path: '',
        name: '/schoolmen/',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/index.vue'),
        /* no children */
        meta: {
          "layout": "blank",
          "namespace": "schoolmen"
        }
      },
      {
        path: 'error',
        name: '/schoolmen/error',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/error.vue'),
        /* no children */
        meta: {
          "auth": false,
          "namespace": "schoolmen"
        }
      },
      {
        path: 'joint_evaluations',
        /* internal name: '/schoolmen/joint_evaluations' */
        /* no component */
        children: [
          {
            path: '',
            name: '/schoolmen/joint_evaluations/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/index.vue'),
            /* no children */
            meta: {
              "namespace": "schoolmen"
            }
          },
          {
            path: ':evaluation_id',
            /* internal name: '/schoolmen/joint_evaluations/[evaluation_id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schoolmen/joint_evaluations/[evaluation_id]/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'batch_assign_homeworks',
                /* internal name: '/schoolmen/joint_evaluations/[evaluation_id]/batch_assign_homeworks' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/joint_evaluations/[evaluation_id]/batch_assign_homeworks/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  },
                  {
                    path: ':batch_assign_homework_id',
                    /* internal name: '/schoolmen/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/schoolmen/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index.vue'),
                        /* no children */
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'edit',
                        name: '/schoolmen/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit.vue'),
                        /* no children */
                        meta: {
                          "namespace": "schoolmen"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "schoolmen"
                    }
                  },
                  {
                    path: 'new',
                    name: '/schoolmen/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'homeworks',
                /* internal name: '/schoolmen/joint_evaluations/[evaluation_id]/homeworks' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/joint_evaluations/[evaluation_id]/homeworks/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/homeworks/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'joint_evaluation_courseware_relations',
                /* internal name: '/schoolmen/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  },
                  {
                    path: 'new',
                    name: '/schoolmen/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              }
            ],
            meta: {
              "namespace": "schoolmen"
            }
          },
          {
            path: ':evaluation_id/edit',
            name: '/schoolmen/joint_evaluations/[evaluation_id].edit',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "schoolmen"
            }
          },
          {
            path: 'new',
            name: '/schoolmen/joint_evaluations/new',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/joint_evaluations/new.vue'),
            /* no children */
            meta: {
              "namespace": "schoolmen"
            }
          }
        ],
        meta: {
          "namespace": "schoolmen"
        }
      },
      {
        path: 'login',
        name: '/schoolmen/login',
        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/login.vue'),
        /* no children */
        meta: {
          "auth": false,
          "layout": "blank",
          "namespace": "schoolmen"
        }
      },
      {
        path: 'profile',
        /* internal name: '/schoolmen/profile' */
        /* no component */
        children: [
          {
            path: 'password',
            /* internal name: '/schoolmen/profile/password' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schoolmen/profile/password/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/profile/password/index.vue'),
                /* no children */
                meta: {
                  "namespace": "schoolmen"
                }
              }
            ],
            meta: {
              "namespace": "schoolmen"
            }
          }
        ],
        meta: {
          "namespace": "schoolmen"
        }
      },
      {
        path: 'schools',
        /* internal name: '/schoolmen/schools' */
        /* no component */
        children: [
          {
            path: '',
            name: '/schoolmen/schools/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/index.vue'),
            /* no children */
            meta: {
              "layout": "blank",
              "namespace": "schoolmen"
            }
          },
          {
            path: ':school_id',
            /* internal name: '/schoolmen/schools/[school_id]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/schoolmen/schools/[school_id]/',
                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'batch_workathons',
                /* internal name: '/schoolmen/schools/[school_id]/batch_workathons' */
                /* no component */
                children: [
                  {
                    path: 'new',
                    name: '/schoolmen/schools/[school_id]/batch_workathons/new',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/batch_workathons/new.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'clazzes',
                /* internal name: '/schoolmen/schools/[school_id]/clazzes' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/schools/[school_id]/clazzes/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  },
                  {
                    path: ':clazz_id',
                    /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/',
                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/index.vue'),
                        /* no children */
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'homeworks',
                        /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/homeworks' */
                        /* no component */
                        children: [
                          {
                            path: ':homework_id',
                            /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]' */
                            /* no component */
                            children: [
                              {
                                path: '',
                                name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index.vue'),
                                /* no children */
                                meta: {
                                  "namespace": "schoolmen"
                                }
                              },
                              {
                                path: 'homework_papers',
                                /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers' */
                                /* no component */
                                children: [
                                  {
                                    path: ':homework_paper_id',
                                    /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]' */
                                    /* no component */
                                    children: [
                                      {
                                        path: '',
                                        name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/',
                                        component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index.vue'),
                                        /* no children */
                                        meta: {
                                          "namespace": "schoolmen"
                                        }
                                      }
                                    ],
                                    meta: {
                                      "namespace": "schoolmen"
                                    }
                                  }
                                ],
                                meta: {
                                  "namespace": "schoolmen"
                                }
                              }
                            ],
                            meta: {
                              "namespace": "schoolmen"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'students',
                        /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/students' */
                        /* no component */
                        children: [
                          {
                            path: 'new',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/students/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/students/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'studentships',
                        /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/studentships' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/studentships/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          },
                          {
                            path: 'new',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/studentships/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'teachers',
                        /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teachers' */
                        /* no component */
                        children: [
                          {
                            path: 'new',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teachers/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teachers/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'teacherships',
                        /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teacherships' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teacherships/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          },
                          {
                            path: ':teachership_id',
                            /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]' */
                            /* no component */
                            children: [
                              {
                                path: 'edit',
                                name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit',
                                component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit.vue'),
                                /* no children */
                                meta: {
                                  "namespace": "schoolmen"
                                }
                              }
                            ],
                            meta: {
                              "namespace": "schoolmen"
                            }
                          },
                          {
                            path: 'new',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/teacherships/new',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/new.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "schoolmen"
                        }
                      },
                      {
                        path: 'workathons',
                        /* internal name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/workathons' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/schoolmen/schools/[school_id]/clazzes/[clazz_id]/workathons/',
                            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/workathons/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "schoolmen"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "schoolmen"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'school_homework_reports',
                /* internal name: '/schoolmen/schools/[school_id]/school_homework_reports' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/schools/[school_id]/school_homework_reports/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/school_homework_reports/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'students',
                /* internal name: '/schoolmen/schools/[school_id]/students' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/schools/[school_id]/students/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/students/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'textbook_forms',
                /* internal name: '/schoolmen/schools/[school_id]/textbook_forms' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/schools/[school_id]/textbook_forms/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  },
                  {
                    path: ':textbook_form_id/edit',
                    name: '/schoolmen/schools/[school_id]/textbook_forms/[textbook_form_id].edit',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/[textbook_form_id].edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  },
                  {
                    path: 'new',
                    name: '/schoolmen/schools/[school_id]/textbook_forms/new',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/new.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              },
              {
                path: 'workathons',
                /* internal name: '/schoolmen/schools/[school_id]/workathons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/schoolmen/schools/[school_id]/workathons/',
                    component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/schools/[school_id]/workathons/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "schoolmen"
                    }
                  }
                ],
                meta: {
                  "namespace": "schoolmen"
                }
              }
            ],
            meta: {
              "namespace": "schoolmen"
            }
          }
        ],
        meta: {
          "namespace": "schoolmen"
        }
      },
      {
        path: 'students',
        /* internal name: '/schoolmen/students' */
        /* no component */
        children: [
          {
            path: '',
            name: '/schoolmen/students/',
            component: () => import('/private/var/folders/19/tk4b9t697nx3fmzkm4cn0c6h0000gn/T/capsum/Users/guoxiongwei/Documents/workspace/leaf-deploy/school/school/app/javascript/schoolmen/pages/students/index.vue'),
            /* no children */
            meta: {
              "namespace": "schoolmen"
            }
          }
        ],
        meta: {
          "namespace": "schoolmen"
        }
      }
    ],
    meta: {
      "namespace": "schoolmen"
    }
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

