import { bva } from '$ui/utils'
import type { NavPresenter } from '$ui/nav'

export const tabs = {
  list: bva('flex flex-wrap border-b gap-2', {}),
  item: bva(`
    rounded-t-md px-4 h-9 content-center box-border -mb-px border border-transparent text-primary block
    hover:text-foreground hover:border-border
    disabled:text-silence disabled:border-transparent
    data-[state=active]:text-foreground data-[state=active]:bg-card data-[state=active]:border-border data-[state=active]:border-b-0 data-[state=active]:pb-px
  `, {}),
} satisfies NavPresenter


export const pill = {
  list: bva('flex gap-3', {}),
  item: bva(`
    rounded-md px-3 py-2
    text-gray-500 hover:text-gray-700
    data-[state=active]:bg-primary/10 data-[state=active]:text-primary
  `, {
    size: {
      xs: 'text-xs px-2 py-1',
      sm: 'h-7 leading-7',
      default: 'py-1',
    }
  })
} satisfies NavPresenter


export const underline = {
  list: bva('-my-px flex gap-4', {}),
  item: bva(`
    inline-flex items-center border-b-2 pt-2 pb-1 border-transparent text-secondary -mb-[2px]
    hover:border-muted hover:text-foreground
    data-[state=active]:border-primary data-[state=active]:text-primary
  `, {}),
} satisfies NavPresenter

declare module '$ui/nav' {
  interface NavPresenters {
    tabs: NavPresenter
    pill: NavPresenter
    underline: NavPresenter
  }
}